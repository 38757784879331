import { graphql } from "gatsby";
import WhyLendingPeople from "../../templates/whyLendingPeople";
import links from "/src/scripts/aboutLinks";

const WhyLendingPeoplePage = ({
	data: {
		craft: { entry },
	},
}) => <WhyLendingPeople entry={entry} subLinks={links} />;

export const pageQuery = graphql`
	query WhyLendingPeoplePage {
		craft {
			entry(slug: "why-lending-people") {
				... on CraftCMS_whyLendingPeople_whyLendingPeople_Entry {
					heroTitle
					heroText
					pageContentRows {
						... on CraftCMS_pageContentRows_pageContentRowsItem_BlockType {
							pageContentRowsItemTitle
							pageContentRowsItemText
							pageContentRowsItemImage {
								width
								height
								url
								title
								imagerX(transform: "maxWidth-1920") {
									width
									height
									url
								}
								imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
									width
									height
									url
								}
							}
						}
					}
					centerBannerImage {
						width
						height
						url
						title
						imagerX(transform: "maxWidth-1920") {
							width
							height
							url
						}
						imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
							width
							height
							url
						}
					}
					centerBannerTitle
					centerBannerText
					centerBannerButton {
						element {
							slug
							uri
						}
						ariaLabel
						customText
						target
						text
						title
						type
						url
					}
					metaTitle
					metaDescription
					metaShareImage {
						filename
						url
					}
				}
			}
		}
	}
`;

export default WhyLendingPeoplePage;
